import React, { useEffect} from "react"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import image15 from "../../assets/images/general/15.png";
import amish1 from "../../assets/images/tour/amish_1.png";
import amish2 from "../../assets/images/tour/amish_2.png";
import amish3 from "../../assets/images/tour/amish_3.png";
import amish4 from "../../assets/images/tour/amish_4.png";
import empire from "../../assets/images/tour/amtrak_empire.png";
import chief from "../../assets/images/tour/amtrak_chief.png";
import zephyr from "../../assets/images/tour/amtrak_zephyr.png";
import "./Tours.scss"

const Tours = () => {

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <main>
			<Header />
			<section className="hero">
            <img src={image15} alt="South Dakota" className="hero__img"/>
         </section>
			<article className="tour">
            <h2 className="tour__header">Wilson Western Tours</h2>
            <div className="tour__cont">
               <h3 className="tour__sub-header">Journeys Through the Heart of the Wild West</h3>
               <p className="tour__description">
					Wilson’s Western Tours is your premier choice for exploring the western United States and turning your dreams into reality. 
					These tours offer an adventure like no other. I handle all the logistics of your trip, including train ticket reservations, transportation, and lodging, so you can sit back and enjoy the journey completely stress-free. 
					Each trip is entirely personalized—no two tours are the same—tailored to fit your group’s unique needs and desires.
               </p>
            </div>
         </article>
			<section class="image__grid">
				<div class="image__grid-item">
					<img src={amish1} alt="Tour destination 1"/>
				</div>
				<div class="image__grid-item">
					<img src={amish2} alt="Tour destination 2"/>
				</div>
				<div class="image__grid-item">
					<img src={amish3} alt="Tour destination 3"/>
				</div>
				<div class="image__grid-item">
					<img src= {amish4} alt="Tour destination 4"/>
				</div>
			</section>
			<article className="include">
				<h2 className="include__header">What’s Included in Your Adventure</h2>
				<p className="include__description">
					Your trip includes complete logistical planning: train tickets, lodging, park entry fees, and transportation during the tour. 
					Additionally, I’ll provide transportation for any other needs your group may have, such as visits to stores, restaurants, or post offices.
					Two large coolers will be available for storing drinks and food throughout the journey. Depending on your chosen itinerary, I can arrange interpretive tours at historic sites, subject to timing and availability. 
					As a special touch, I’ll also provide complimentary photos to document your adventure.
				</p>
			</article>
			<article className="train">
				<h2 className="train__header">Getting Here</h2>
				<p className="train__description">
					Amtrak train routes will transport you and your group to the starting point of your adventure, where I will personally greet you at the station and kick off your journey!
					During booking, you can choose between two ticket options: Sleeper Car or Coach. Sleeper Cars offer added comfort and include meals from the dining car, while Coach tickets provide standard seating with meals available for purchase on board.
					Please note that Sleeper Car tickets are typically more expensive than Coach seats, allowing you to select the option that best suits your preferences and budget.
				</p>
				<div className="train__cont">
					<h3 className="train__sub-header">Our Favorite Routes</h3>
					<div className="train__routes">
						<div className="train__route">
							<div className="train__image-wrapper">
								<img src={empire} alt="Empire Builder Train" className="train__image" />
								<div className="train__label">Empire Builder</div>
							</div>
							<p className="train__route-description">Amtrak’s Empire Builder offers a scenic journey through the northern U.S., featuring views of the Mississippi River, Montana’s Glacier National Park, and the Cascade Mountains, connecting Chicago to Seattle or Portland.</p>
						</div>
						<div className="train__route">
							<div className="train__image-wrapper">
								<img src={zephyr} alt="California Zephyr Route" className="train__image" />
								<div className="train__label">California Zephyr</div>
							</div>
							<p className="train__route-description">The California Zephyr travels through the heart of the Rockies and the Sierra Nevadas, offering breathtaking views from Chicago to San Francisco.</p>
						</div>
						<div className="train__route">
							<div className="train__image-wrapper">
								<img src={chief} alt="Southwest Chief Route" className="train__image" />
								<div className="train__label">Southwest Chief</div>
							</div>
							<p className="train__route-description">The Southwest Chief crosses desert landscapes, canyons, and mountains, connecting Chicago to Los Angeles through the Southwest’s iconic scenery.</p>
						</div>
					</div>
				</div>
			</article>
			<article className="lodging">
        		<h2 className="lodging__header">Lodging</h2>
        		<p className="lodging__description">
					Lodging options are dependent on the tour you choose or the amount of area you wish to see. You will stay in a combination of
					hotels, cabin and home rentals. Most house and cabin rentals will be stocked with all your basic amenities you would need such
					as pots, pans, laundry rooms, etc. The price of your trip will be dependent on various lodging needs. For example, the more
					guests you have generally increases the price of lodging and your overall trip cost if you require separate hotel rooms or
					additional bedrooms.
        		</p>
      		</article>
			<article className="pricing">
				<h2 className="pricing__header">Pricing</h2>
				<p className="pricing__description">
					Contact us for a personalized quote! Pricing depends on several factors, including the number of guests, your chosen tour, and specific trip details. 
					Bookings made well in advance typically qualify for lower rates. Let us help you plan a trip that fits your group and budget!
				</p>
			</article>
			<article className="pricing">
				<h2 className="pricing__header">Disclaimer</h2>
				<p className="pricing__description">
					We are not affiliated with Amtrak, hotels, National Parks, house rentals, or any other company other than Wilson’s Western Tours.
				</p>
			</article>
			<Footer />
      </main>
   );
}
export default Tours;



