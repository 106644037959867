import React from "react";
import logo from '../../assets/images/logos/wwt-logo.png'
import facebook from '../../assets/images/logos/Facebook-Icon.svg'
import './Footer.scss';

const Footer = () => {

    return (
       <footer className="footer">
          <div className="footer__logo-cont">
            <img src={logo} alt="Wilson Western Tours & Transporation Logo" className="footer__logo" />
          </div>
          <div className="footer__managed-by">
          
          </div>
          <div className="footer__contact">
            <p className="footer__header">Contact Us</p>
            <a className="footer__text" href="mailto:wilsonswesterntours@gmail.com">Email: wilsonswesterntours@gmail.com</a><br/>
            <a className="footer__mobile" href="tel:+12089939844">Mobile: +1 (208) 993-9844</a>
          </div>
          <div className="footer__social">
            <p className="footer__header">Social Media</p>
            <a href="https://www.facebook.com/profile.php?id=100066441682423" target="_blank" rel="noreferrer" class="facebook">
              <img src={facebook} alt="Facebook Logo" class="facebook__logo" />
            </a>  
          </div>
       </footer>
    );
 }
 
 export default Footer
