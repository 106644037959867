import React, {useEffect} from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Home.scss";

// Images
import image1 from "../../assets/images/general/1.png";
import image2 from "../../assets/images/general/2.png";
import image3 from "../../assets/images/general/3.png";
import image5 from "../../assets/images/general/5.png";
import image6 from "../../assets/images/general/6.png";
import image7 from "../../assets/images/general/7.png";
import image8 from "../../assets/images/general/8.png";
import image9 from "../../assets/images/general/9.png";
import image10 from "../../assets/images/general/10.png";
import image11 from "../../assets/images/general/11.png";
import image12 from "../../assets/images/general/12.png";
import image13 from "../../assets/images/general/13.png";
import image14 from "../../assets/images/general/14.png";
import image15 from "../../assets/images/general/15.png";
import image16 from "../../assets/images/general/16.png";
import image17 from "../../assets/images/general/17.png";
import image18 from "../../assets/images/general/18.png";
import image19 from "../../assets/images/general/19.png";
import image20 from "../../assets/images/general/20.png";



const Home = () => {

   const images = [image1, image2, image3, image7, image5, image6, image20, image8, image9, image10];
   const settings = {
      dots: true,
      infinite: true,
      speed: 1100,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
   };
   // const imageTexts = [
   //    "Explore the Western Frontier",
   //    "Adventure Awaits You",
   //    "A Journey Through Nature",
   //    "Breathtaking Landscapes",
   //    "Unforgettable Experiences",
   //    "Discover the Unknown",
   //    "Wilderness Adventures",
   //    "Embrace the Wild",
   //    "The Ultimate Outdoor Tour",
   //    "Every Mile Tells a Story"
   // ];

   const tourArea = [
      { location: "South Dakota", image: image13 },
      { location: "Colorado", image: image12 },
      { location: "Idaho", image: image11 },
      { location: "Wyoming", image: image8 },
      { location: "Arizona", image: image14 },
      { location: "Utah", image: image15 },
      { location: "Montana", image: image16 },
      { location: "Washington", image: image17 },
      { location: "Oregon", image: image18 },
      { location: "California", image: image19 }
   ];
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
       <main className="home">
         <Header />
         <hero>
            <div className="hero">
               <Slider {...settings}>
                  {images.map((image, index) => (
                     <div className="hero__image-cont" key={index}>
                        <img src={image} alt={`Slide ${index}`} className="hero__image" />
                        {/* <div className="hero__text">
                           <h2>{imageTexts[index]}</h2>
                        </div> */}
                     </div>
                  ))}
               </Slider>
            </div>
         </hero>
         <article className="article1">
            <h1 className="article1__header">Wilson's Western Tours<br/> & <br/> Transportation</h1>
            <div className="article1__description-cont">
               <p className="article1__description">
                  At Wilson’s Western Tours and Transportation, we provide reliable and stress-free transportation services. 
                  From airport pickups and drop-offs to vehicle shuttles for your backcountry adventure to full-service vacations, 
                  our friendly and knowledgeable drivers offer a hassle-free and comfortable experience tailored to both small groups and large parties.
               </p>
            </div>
         </article>
         <section>
            <div className="shuttle">
               <h2 className="shuttle__header">Transportation Services</h2>
               <p className="shuttle__description">
                  Our shuttle service offers comfortable, reliable transportation to various destinations—whether you’re catching a flight, 
                  need a vehicle shuttle, or want group transportation for a special event.
               </p>
               <p className="shuttle__teaser"><strong>Popular routes:</strong></p>
               <p className="shuttle__teaser">Salmon to Idaho Falls: <strong>$365</strong></p>
               <p className="shuttle__teaser">Salmon to Missoula Airport: <strong>$365</strong></p>
               <p className="shuttle__teaser">Salmon to Boise Airport: <strong>$850</strong></p>
               <p className="shuttle__teaser">Salmon to River & Recreation Areas: <strong>Starting at $450</strong></p>
               <p className="shuttle__cta">
                  See our full list of Transportation Services and Vehical Shuttles!
               </p>
               <Link to ='/shuttle' className="button">
                  <span className="button__text">View Shuttle Services</span>
               </Link>
            </div>
         </section>
         <section className="tour">
            <div className="tour__cont">
               <h2 className="tour__header">Our Tours</h2>
               <p className="tour__description">
                  Discover the wild beauty of the American West with Wilson’s Western Tours, where every journey is a custom-tailored adventure.  
                  Whether you're exploring the rugged terrains of Idaho, the iconic landmarks of Arizona, or the scenic routes of Montana, our tours offer a perfect balance of adventure and relaxation. 
                  Enjoy stress-free travel, with Sundays dedicated to rest in comfortable accommodations. 
                  This is more than a tour—it’s an unforgettable experience!
               </p>
               <Link to ='/tours' className="button">
                  <span className="button__text">Find Out More</span>
               </Link>
            </div>
            <div className="tour__area-cont">
               <h2 className="tour__sub-header">Our Tour Areas</h2>
               <div className="tour__area">
                  {tourArea.map((area, index) => (
                     <div className="tour__img-cont" key={index}>
                        <h3 className="tour__img-location">{area.location}</h3>
                        <img className="tour__img" src={area.image} alt={area.location} />
                     </div>
                  ))}
               </div>
             </div>
         </section>
         
         <Footer />
      </main>
   );
}

export default Home;
