import React, { useEffect} from "react"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import bryce from "../../assets/images/about/bryce_van.png";
import lookout from "../../assets/images/about/van_lookout.png";
import sideview from "../../assets/images/about/van_sideview.png";
import amish from "../../assets/images/about/van_amish.png";
import loneamish from "../../assets/images/about/lone_amish-man.png";


import "./About.scss"


const About = () => {

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <main>
         <Header />
         <section className="hero">
            <img src={bryce} alt="Company owner sitting in van" className="hero__img"/>
         </section>
         <article className="about">
            <h2 className="about__header">About Us</h2>
            <div className="about__description">
               We are a family-run business located in the beautiful mountain town of Salmon Idaho. 
               Our team strives for excellence in providing reliable transportation to ensure the best possible experience for you and your group. 
               We recognize and appreciate the value of your trust. 
               Please don’t hesitate to <a href="mailto:wilsonswesterntours@gmail.com">Contact Us</a> for any further information.
            </div>
         </article>
         <article className="contact">
            <h2 className="contact__header">Contact Information</h2>
            <h3 className="contact__sub-header">Contact Anytime!</h3>
            <p className="contact__description">
               📧 Email us at: <a href="mailto:wilsonswesterntours@gmail.com">wilsonswesterntours@gmail.com</a> <br />
               📞 Call us at: <a href="tel:+12089939844">(208) 993-9844</a>
            </p>
            <div className="contact__gallery">
               <img src={lookout} alt="Beautiful mountain scenery" className="contact__gallery-img" />
               <img src={sideview} alt="Tour van on a scenic road" className="contact__gallery-img" />
               <img src={amish} alt="Happy group of travelers" className="contact__gallery-img" />
               <img src={loneamish} alt="Happy group of travelers" className="contact__gallery-img" />
            </div>
         </article>
         <Footer />
      </main>
   );

}

export default About;




