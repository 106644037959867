import React, { useEffect} from "react"
// import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import image22 from "../../assets/images/general/22.png";
import image23 from "../../assets/images/general/23.png";
import image24 from "../../assets/images/general/24.png";
import  "./Shuttle.scss"

const Shuttle = () => {


   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <main>
         <Header />
         <section className="hero">
            <img src={image23} alt="South Dakota" className="hero__img"/>
         </section>
         <section className="shuttles">
            <h2 className="shuttles__header">Transportation Services</h2>
            <div className="shuttles__cont">
               <h3 className="shuttles__sub-header">Convenient and Reliable</h3>
               <p className="shuttles__description">
                  We offer a wide range of transportation services customized to meet your individual needs. 
                  Airport transport, vehicle shuttles and group transportation for special events or group vacations are just some of the services we offer.
                  We strive to ensure your safety, comfort and satisfaction every step of the way.
               </p>
            </div>
         </section>
         <section className="offered">
            <div className="offered__cont">
               <h3 className="offered__header">Services Offered</h3>
               <ul className="offered__li-cont">
                  <li className="offered__services">Taxi Transportation</li>
                  <li className="offered__services">Vehicle Shuttles</li>
                  <li className="offered__services">Weddings & Private Events</li>
               </ul>
            </div>
            <div className="offered__img-cont">
               <img src={image24} alt="Highway through canyon" className="offered__img"/>
            </div>
         </section>
         <section className="pricing">
            <div className="pricing__section">
               <h3 className="pricing__header">Pricing</h3>
               <div className="pricing__cont">
                  <h3 className="pricing__sub-header">Vehicle Shuttles:</h3>
                  <ul className="pricing__li-cont">
                     <li className="pricing__services">Salmon to Stanley: <strong>$450.00</strong></li>
                     <li className="pricing__services">Boundary Put in to Cache Bar or Corncreek: <strong>$600</strong></li>
                     <li className="pricing__services">Challis to Cache Bar or Corn Creek: <strong>$475</strong></li>
                     <li className="pricing__services">Salmon to Cache Bar or Corn Creek: <strong>$450</strong></li>
                     <li className="pricing__services">Big Horn Crags: <strong>$500 (Road Condition Depending)</strong></li>
                  </ul>
               </div>
               <div className="pricing__cont">
                  <h3 className="pricing__sub-header">Taxi Transportation:</h3>
                  <ul className="pricing__li-cont">
                     <li className="pricing__services">Salmon to Missoula airport or Idaho falls: <strong>$365.00</strong></li>
                     <li className="pricing__services">Salmon to Boise airport: <strong>$850</strong></li>
                     <li className="pricing__services">Salmon to Salt lake city: <strong>$950</strong></li>
                     <li className="pricing__services">Salmon to Leadore: <strong>$150</strong></li>
                     <li className="pricing__services">Salmon to Challis: <strong>$250</strong></li>
                     <li className="pricing__services">Salmon to Stanley: <strong>$350</strong></li>
                     <li className="pricing__services">Salmon to Butte MT: <strong>$400</strong></li>
                     <li className="pricing__services">Salmon to Bozeman: <strong>$550.00</strong></li>
                  </ul>
               </div>
               <p className="pricing__event">
                  For <strong className="pricing__strong">Weddings</strong> and <strong className="pricing__strong">Private Event</strong> transportation, <a href="mailto:wilsonswesterntours@gmail.com">Contact Us</a> for pricing based on an hourly rate.
               </p>
               <div className="pricing__section-img" >
                  <img src={image22} alt="Rocky Mountains" className="pricing__img"/>
               </div>
            </div>
         </section>
         <Footer />
      </main>
   );
}

export default Shuttle;



