import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logos/wwt-logo.png'
import "./Header.scss"

const Header = () => {
   const [isMenuOpen, setIsMenuOpen] = useState(false);
   const page = useLocation();

   const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
   };

   return (
      <header className="header">
         <Link to="/" className="header__logo-link">
            <img src={logo} alt="Wilson Western Tours & Transportation" className="header__logo" />
         </Link>
         <div className={`header__hamburger ${isMenuOpen ? "open" : ""}`} onClick={toggleMenu}>
            <div className="header__ham-line"></div>
            <div className="header__ham-line"></div>
            <div className="header__ham-line"></div>
         </div>
         <ul className={`header__dropdown ${isMenuOpen ? "open" : "hidden"}`}>
            {page.pathname !== "/" && <li className="header__drop-item"><Link className="header__item-link" to="/">Home</Link></li>}
            {page.pathname !== "/shuttle" && <li className="header__drop-item"><Link className="header__item-link" to="/shuttle">Transportation Services</Link></li>}
            {page.pathname !== "/tours" && <li className="header__drop-item"><Link className="header__item-link" to="/tours">Tours</Link></li>}
            {page.pathname !== "/about" && <li className="header__drop-item"><Link className="header__item-link" to="/about">About</Link></li>}
         </ul>
      </header>
   );
}

export default Header;
